import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import ChatboxPrev from './chatboxPrev';
import './CreateWhatsappTemplate.css';
import ConfirmationModal from './ConfirmationModal';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';

const CreateWhatsappTemplate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const query = new URLSearchParams(location.search);
    const templateId = query.get('template_id');

    console.log(`Received template_id: ${templateId}`);

    // Function to replace placeholders with example texts
    const replacePlaceholders = (text, example) => {
        if (!example) return text;
        let replacedText = text;
        example.forEach((ex, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, ex);
        });
        return replacedText;
    };

    // Function to decode the token and extract user ID
    const getUserIdFromToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id;
            } catch (err) {
                console.error('Error decoding token:', err);
            }
        }
        return null;
    };

    // Function to fetch templates
    const fetchTemplates = async () => {
        const userId = getUserIdFromToken();
        if (!userId) {
            setError('Invalid token or user ID not found');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`https://api.lohith.in:3007/api/v1/wa/get_wa_templates/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Log the full API response
            console.log('Full API Response:', response.data);

            const templatesWithPreview = response.data.templates.map((template) => {
                let header = '';
                let body = '';
                let footer = '';
                let button = '';

                try {
                    const code = JSON.parse(template.code || '{}');
                    code.components.forEach((component) => {
                        switch (component.type) {
                            case 'HEADER':
                                header = replacePlaceholders(component.text, component.example?.header_text || []);
                                break;
                            case 'BODY':
                                body = replacePlaceholders(component.text, component.example?.body_text?.flat() || []);
                                break;
                            case 'FOOTER':
                                footer = replacePlaceholders(component.text, component.example?.footer_text || []);
                                break;
                            case 'BUTTONS':
                                button = component.buttons.map((b) => b.text).join(', ');
                                break;
                            default:
                                break;
                        }
                    });
                } catch (e) {
                    console.error('Error parsing code:', e);
                }

                return {
                    ...template,
                    header,
                    body,
                    footer,
                    button,
                };
            });

            setTemplates(templatesWithPreview);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching templates:', err);
            setError('Failed to fetch templates');
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchTemplates();
    }, []);

    const handleCreateNewTemplate = () => {
        navigate('/template-page');
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'status-approved';
            case 'REJECTED':
                return 'status-rejected';
            case 'PENDING':
                return 'status-pending';
            default:
                return '';
        }
    };

    const handleCardClick = (template) => {
        console.log('Selected Template:', template);
        setSelectedTemplate(template);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedTemplate(null);
    };

  
    const handleModalConfirm = async () => {
        if (!selectedTemplate) {
            console.error('No template selected');
            return;
        }

        const userId = getUserIdFromToken();
        const waTemplateId = selectedTemplate.id;

        if (!templateId || !waTemplateId || !userId) {
            console.error('Missing required data:', { templateId, waTemplateId, userId });
            return;
        }

        try {
            const response = await axios.post(
                'https://api.lohith.in:3007/api/v1/wamap/template-wa-mapping',
                {
                    template_id: Number(templateId),
                    wa_template_id: Number(waTemplateId),
                    userid: Number(userId)
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            console.log('API Response:', response.data);

            // Navigate to /modify_Whatsapp_Template and pass the template data
            navigate(`/modify_Whatsapp_Template`, {
                state: {
                    templateId,
                    waTemplateId,
                    userId,
                    code: selectedTemplate.code, // Pass the code data to ModifyTemplate page
                },
            });

            setShowModal(false);
        } catch (error) {
            console.error('Error submitting data:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
            }
        }
    };


    return (
        <>
  
            <div className="cwt">
                <div className="part1">
                    <h3>Create WhatsApp Template Here</h3>
                    <button className="button-create" onClick={handleCreateNewTemplate}>
                        Create WhatsApp Template
                    </button>
                </div>
                <hr />
                <div className="create-whatsapp-template12">
                    <h3 style={{ marginLeft: '20px', color: 'white' }}>Recently Created WhatsApp Template</h3>

                    {loading && <p>Loading templates...</p>}
                    {error && <p className="error-message">{error}</p>}

                    {!loading && !error && (
                        <div className="templates-list12">
                            {templates.length > 0 ? (
                                templates.map((template, index) => (
                                    <div
                                        key={index}
                                        className="template-card12"
                                        onClick={() => handleCardClick(template)}
                                    >
                                        <div className="bg112">
                                            <h4 className="tp1">Template Preview</h4>
                                            <ChatboxPrev
                                                header={template.header}
                                                body={template.body}
                                                footer={template.footer}
                                                button={template.button}
                                            />
                                        </div>
                                        <h5 className="temp1name">{template.name}</h5>

                                        <div className="template-info category-info">
                                            <p>Category: {template.wa_template_category}</p>
                                        </div>
                                        <div
                                            className={`template-status ${getStatusClass(
                                                template.wa_template_status
                                            )}`}
                                        >
                                            <span style={{ color: 'black' }}>STATUS:</span> {template.wa_template_status}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No templates found.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <ConfirmationModal
                    show={showModal}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    message="Are you sure you want to proceed?"
                />
            )}
        </>
    );
};

export default CreateWhatsappTemplate;
