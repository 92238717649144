import React, { useState } from 'react';
import backgroundImage from '../assets/bc.png'; // Adjust the path if necessary
import { useTheme } from './ThemeContext'; // Import the useTheme hook
import './Content1.css';
import CreateWhatsappTemplate from './CreateWhatsappTemplate';
import TemplateList from './TemplateList';
import AdvBar from './AdvBar/AdvBar';

const Content1 = React.memo(() => {
    const { theme } = useTheme(); // Use the theme from context
    const [hideTemplate, sethideTemplate] = useState(false);
    const contentStyle = {
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        minHeight: '100vh',
        color: theme === 'dark' ? 'white' : 'black', // Adjust text color based on theme
        maxWidth: '100%',
        backgroundColor: theme === 'dark' ? '#000' : '#ecf0f6', // Adjust background based on theme
    };

    return (
        // <div style={contentStyle} className={`con1 ${theme}`}>
        //    {!hideTemplate?<><AdvBar />
        //         <TemplateList hideTemplate={hideTemplate} sethideTemplate={sethideTemplate} /></>
        //         : <CreateWhatsappTemplate  />}
        // </div>
        <div style={contentStyle} className={`con1 ${theme}`}>
          <AdvBar />
                <TemplateList  />
                
        </div>
    );
});

export default Content1;
