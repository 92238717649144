import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import MainLayout from './components/MainLayout/MainLayout';
import Login from './components/Login/LogIn';
import Register from './components/Register/Register';
import Content1 from './components/Content1';
import Content2 from './components/Content2';
import Content3 from './components/Content3';
import Content4 from './components/Content4';
import { ThemeProvider } from './components/ThemeContext';
import SelectTemplate from './components/selectTemplate/SelectTemplate';
import CreateWhatsappTemplate from './components/CreateWhatsappTemplate';
import modifyTemplate from './components/modifyTemplate/modifyTemplate';


const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime; // Check if the token is still valid
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
};

const PrivateRoute = ({ element: Component }) => {
    return isTokenValid() ? <Component /> : <Navigate to="/login" />;
};

const AppRouter = () => {
    const tokenValid = isTokenValid();

    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    {/* Redirect to main if token is valid, else redirect to login */}
                    <Route path="/" element={<Navigate to={tokenValid ? "/main/conten1" : "/login"} />} />
                    {/* <Route path="/" element={<Navigate to={tokenValid ? "/main/SelectTemplate" : "/login"} />} /> */}
                    {/* Public routes */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    {/* Protected routes */}
                    <Route path="/main" element={<PrivateRoute element={MainLayout} />}>
                        {/* Redirect to Content1 if no specific route is provided */}
                        <Route index element={<Navigate to="content1" />} />
                        <Route path="content1" element={<Content1 />} />
                        <Route path="content2" element={<Content2 />} />
                        <Route path="content3" element={<Content3 />} />
                        <Route path="content4" element={<Content4 />} />
                        <Route path="template-page" element={<SelectTemplate />} />
                        <Route path="create_whatsapp_template" element={<CreateWhatsappTemplate />} />
                        
                       
                    </Route>
                    <Route path="/template-page" element={<PrivateRoute element={SelectTemplate} />} />
                    
                    <Route path="modify_Whatsapp_Template" element={<PrivateRoute element={modifyTemplate}/>} />
                    {/* <Route path="/create_whatsapp_template" element={<PrivateRoute element={CreateWhatsappTemplate} />} /> */}
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default AppRouter;
