// ConfirmationModal.js
import React from 'react';
import './ConfirmationModal.css'; // Make sure this file exists

const ConfirmationModal = ({ show, onClose, onConfirm, message }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay21">
            <div className="modal-content21">
                <h3>{message}</h3>
                <div className="modal-buttons21">
                    <button className="modal-button21" onClick={onConfirm}>
                        Yes
                    </button>
                    <button className="modal-button21" onClick={onClose}>
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
