// import React, { useState, useEffect } from 'react';
// import Navbar from '../Navbar/Navbar';
// import Sidebar from '../Sidebar/Sidebar';
// import './NextComponent.css';
// import { jwtDecode } from 'jwt-decode';
// import { useNavigate } from 'react-router-dom';
// import { FaBullhorn, FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle, FaImage, FaVideo, FaFileAlt, FaMapMarkerAlt, FaTextHeight, FaMinusCircle } from 'react-icons/fa';
// import axios from 'axios'; // Add axios import if using axios
// import tempImg from '../../assets/images/bcl.jpg';
// import ErrorModal from '../ErrorModal/ErrorModal ';


// const NextComponent = ({ onBackClick, selectedCategory }) => {
//     const [selectedOption, setSelectedOption] = useState('None');
//     const [text, setText] = useState('');
//     const [headerText, setHeaderText] = useState('');
//     const [footerText, setFooterText] = useState('');
//     const [file, setFile] = useState(null);
//     const [mainSelection, setMainSelection] = useState('');
//     const [showAdditionalFields, setShowAdditionalFields] = useState(false);
//     const [templateName, setTemplateName] = useState('');
//     const [language, setLanguage] = useState('en_US');
//     const [buttons, setButtons] = useState([]);
//     const [secondaryOption, setSecondaryOption] = useState('');
//     const [inputText, setInputText] = useState('');
//     const [message, setMessage] = useState(''); // Renamed from 'text' to 'message'
//     const [variablesList, setVariablesList] = useState([]); // Renamed from 'variables'
//     const [variableCount, setVariableCount] = useState(1); // Renamed from 'nextVariableIndex'
//     const [bodyText, setBodyText] = useState('');
//     const [variableFields, setVariableFields] = useState([]);
//     const [nextVariableIndex, setNextVariableIndex] = useState(1);
//     const [variableHeaderAdded, setVariableHeaderAdded] = useState(false);
//     const [variableText, setVariableText] = useState(''); // Text for the variable
//     const [variableHeaderValue, setVariableHeaderValue] = useState(''); // Value of the variable
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();
//     const [showError, setShowError] = useState(false);
//     const [errorMessage, setErrorMessage] = useState('');


//     const options = [
//         { value: 'None', label: 'None' },
//         { value: 'Image', label: 'Image', icon: <FaImage /> },
//         { value: 'Text', label: 'Text', icon: <FaTextHeight /> },
//         { value: 'Video', label: 'Video', icon: <FaVideo /> },
//         { value: 'Document', label: 'Document', icon: <FaFileAlt /> },
//         { value: 'Location', label: 'Location', icon: <FaMapMarkerAlt /> },
//     ];

//     //Header functions



//     const handleAddHeaderVariable = () => {
//         setHeaderText((prevText) => `${prevText} {{1}}`);
//     };


//     const handleHeaderOptionChange = (e) => {
//         setSelectedOption(e.target.value);
//     };

//     const handleHeaderFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };










//     //BODY functions
//     const extractVariables = (text) => {
//         const regex = /{{(\d+)}}/g;
//         const matches = [...text.matchAll(regex)];
//         const indexes = matches.map((match) => parseInt(match[1], 10));
//         // Sort indexes in ascending order
//         indexes.sort((a, b) => a - b);
//         return indexes;
//     };


//     useEffect(() => {
//         // Extract variables whenever bodyText changes
//         const detectedVariables = extractVariables(bodyText);

//         setVariableFields((prevVariables) => {
//             const currentVariableIndexes = prevVariables.map((variable) => variable.index);
//             const newVariables = detectedVariables.filter((index) => !currentVariableIndexes.includes(index));
//             const updatedVariables = [
//                 ...prevVariables,
//                 ...newVariables.map((index) => ({ index, value: '' })),
//             ];

//             // Remove variables that are not in the bodyText anymore
//             return updatedVariables.filter((variable) => detectedVariables.includes(variable.index));
//         });

//         // Update next variable index to the highest current + 1
//         const maxIndex = detectedVariables.length > 0 ? Math.max(...detectedVariables) : 0;
//         setNextVariableIndex(maxIndex + 1);
//     }, [bodyText]);


//     const handleAddVariable = () => {
//         const newVariable = `{{${nextVariableIndex}}}`;
//         setBodyText((prevBodyText) => prevBodyText + ' ' + newVariable);
//         setNextVariableIndex((prevIndex) => prevIndex + 1);
//     };


//     const handleDeleteVariable = (index) => {
//         setVariableFields((prevVariables) =>
//             prevVariables.filter((variable) => variable.index !== index)
//         );
//         setBodyText((prevBodyText) => prevBodyText.replace(`{{${index}}}`, ''));
//     };


//     const handleVariableChange = (index, value) => {
//         setVariableFields((prevVariables) =>
//             prevVariables.map((variable) =>
//                 variable.index === index ? { ...variable, value } : variable
//             )
//         );
//     };



//     const handleSelectChange = (event) => {
//         const value = event.target.value;
//         setMainSelection(value);

//         if (value === 'option1') {
//             setShowAdditionalFields(true);
//         } else {
//             setShowAdditionalFields(false);
//             // Clear additional fields when "None" is selected
//             setSecondaryOption('');
//             setInputText('');
//         }
//     };

//     const handleOptionChange = (e) => {
//         const value = e.target.value;
//         setSelectedOption(value);

//         if (value !== 'Text') {
//             setHeaderText('');
//         }

//         if (['Image', 'Video', 'Document'].includes(value)) {
//             document.getElementById('fileInput').click();
//         }
//     };

//     const handleSecondaryOptionChange = (event) => {
//         setSecondaryOption(event.target.value);
//     };

//     const handleInputTextChange = (event) => {
//         setInputText(event.target.value);
//     };


//     const handleFileChange = (e) => {
//         const selectedFile = e.target.files[0];
//         setFile(selectedFile);
//     };

//     const handleAction = (action) => {
//         console.log(`${action} button clicked`);
//     };
//     const addButton = () => {
//         if (mainSelection === 'option1' && inputText.trim()) {
//             const newButton = {
//                 type: secondaryOption || 'QUICK_REPLY',
//                 text: inputText,
//             };
//             setButtons([...buttons, newButton]);
//             // Clear input fields after adding button
//             setSecondaryOption('');
//             setInputText('');
//         }
//     };


//     const token = localStorage.getItem('token');
//     console.log('Stored Token:', token); // Log the token to check its presence

//     const generateJSON = (userId) => {
//         // Generate example body text based on variable values as a single array of strings
//         const exampleBodyText = variableFields.length > 0
//             ? [variableFields.map(variable => variable.value)] // Combine all variable values into one array
//             : [];

//         const jsonData = {
//             name: templateName,
//             code: {
//                 name: templateName,
//                 language: language,
//                 category: selectedCategory || "MARKETING", // Use selectedCategory
//                 components: [
//                     {
//                         type: "HEADER",
//                         format: "TEXT",
//                         text: headerText || "how are something {{1}}?",
//                     },
//                     {
//                         type: "BODY",
//                         text: bodyText || "it is new one{{1}}",
//                         ...(exampleBodyText.length > 0 ? {
//                             example: {
//                                 body_text: exampleBodyText,
//                             }
//                         } : {}),
//                     },
//                     ...(footerText ? [{
//                         type: "FOOTER",
//                         text: footerText || "Footer text",
//                     }] : []),
//                     ...(buttons.length > 0 ? [{
//                         type: "BUTTONS",
//                         buttons: buttons.map(button => ({
//                             type: button.type || "QUICK_REPLY",
//                             text: button.text || "Default Button Text",
//                         })),
//                     }] : []),
//                 ],
//             },
//             userid: userId,
//         };

//         console.log(JSON.stringify(jsonData, null, 2));
//         return jsonData;
//     };
//     const refreshToken = async () => {
//         try {
//             const response = await axios.post('https://api.example.com/refresh-token', {
//                 // Provide necessary details to refresh the token
//             });

//             // Save the new token
//             localStorage.setItem('token', response.data.token);
//             return response.data.token;
//         } catch (error) {
//             console.error('Error refreshing token:', error.message);
//             throw error;
//         }
//     };

//     const handleSubmit = async (e) => {
//         let token = localStorage.getItem('token');


//         if (!bodyText.trim()) {
//             setErrorMessage('Body text is required'); // Set error message for empty body text
//             setShowError(true); // Show error modal
//             setLoading(false); // Stop loading
//             return; // Stop execution if body text is empty
//         }

//         if (token) {
//             try {
//                 // Check token validity or refresh if needed
//                 const decodedToken = jwtDecode(token);
//                 const isTokenExpired = new Date().getTime() / 1000 > decodedToken.exp; // Token expiry check

//                 if (isTokenExpired) {
//                     token = await refreshToken();
//                 }

//                 const jsonData = generateJSON(decodedToken.id);

//                 const response = await axios.post(
//                     `https://api.lohith.in:3007/api/v1/wa/create_wa_template`,
//                     jsonData,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                             'Content-Type': 'application/json',
//                         },
//                     }
//                 );

//                 console.log('API response:', response.data);
//                 alert("Template created sucussfully")
//                 navigate('/create_whatsapp_template');
//             } catch (error) {
//                 console.error('Error occurred while sending data:', error.message);
//                 console.error('API error message:', error.response?.data?.message);
//                 console.error('API error details:', error.response?.data?.error);
//                 setErrorMessage(error.response?.data?.message); // Set the error message from the response
//                 setShowError(true); // Show the error modal
//             }
//         } else {
//             console.error('Token not found.');
//             // Handle missing token scenario
//         } e.preventDefault();
//         setLoading(true);
//         setError(null);
//     };

//     const handleCloseModal = () => {
//         setShowError(false);
//         setErrorMessage('');
//     };


//     return (
//         <>
//             <Navbar />
//             <Sidebar />
//             <div className="next-component-container">
//                 <h2 className='p2'>Create Template</h2>
//                 <div className="box-container">
//                     <div className="box left-box">
//                         <div className="firsthead">
//                             <div className="icon-container">
//                                 <FaBullhorn className="icon1" />
//                             </div>
//                             <div className="text-container">
//                                 <h2 className='hhh2'>Your_template_name • English</h2>
//                                 <p>Marketing • Custom</p>
//                             </div>
//                         </div>
//                         <div className="tempname">
//                             <h5>Template name and language</h5>
//                             <div className="template-input">
//                                 <p className='text11'>Name your Template</p>
//                                 <div className="input-group">
//                                     <label className="input-label" htmlFor="templateName">Template Name</label>
//                                     <label className="input-label" htmlFor="selectLanguage">Select Language</label>
//                                     <input
//                                         id="templateName"
//                                         type="text"
//                                         placeholder="Enter a template name"
//                                         maxLength={512}
//                                         className="template-input-field"
//                                         value={templateName}
//                                         onChange={(e) => setTemplateName(e.target.value)}
//                                     />
//                                     <select id="selectLanguage" className="language-select" value={language} onChange={(e) => setLanguage(e.target.value)}>
//                                         <option value="en_US">en_US</option>
//                                         <option value="Spanish">Spanish</option>
//                                         <option value="French">French</option>
//                                     </select>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="hbf">
//                             <div className="template-container">
//                                 <h5><b>Content</b></h5>
//                                 <p>Fill out your header, body and footer section of your template</p>
//                                 <p className='text11'>Header<span className="optional"> • Optional</span></p>
//                                 <div className="header-section">
//                                     <select
//                                         value={selectedOption}
//                                         onChange={handleOptionChange}
//                                         className="header-dropdown"
//                                     >
//                                         {options.map((option) => (
//                                             <option key={option.value} value={option.value}>
//                                                 {option.icon} {option.label}
//                                             </option>
//                                         ))}
//                                     </select>

//                                     {selectedOption === 'Text' && (
//                                         <input
//                                             type="text"
//                                             placeholder="Enter header text..."
//                                             className="header-input"
//                                             value={headerText}
//                                             maxLength={50}
//                                             onChange={(e) => setHeaderText(e.target.value)}
//                                         />
//                                     )}

//                                     <input
//                                         id="fileInput"
//                                         type="file"
//                                         style={{ display: 'none' }}
//                                         onChange={handleFileChange}
//                                     />
//                                     {file && <p className="file-name">Selected File: {file.name}</p>}
//                                 </div>



//                                 <p className="text11">Body</p>
//                                 <div className="body-section">
//                                     <textarea
//                                         className="body-textarea"
//                                         maxLength={1024}
//                                         placeholder="Enter your message..."
//                                         value={bodyText}
//                                         onChange={(e) => setBodyText(e.target.value)}
//                                         required
//                                     />
//                                     <div className="bieavi">
//                                         <div className="char-count">Characters: {bodyText.length} / 1024</div>

//                                         <div className="toolbar">
//                                             <button className="toolbar-button" onClick={() => handleAction('Bold')}>
//                                                 <FaBold />
//                                             </button>
//                                             <button className="toolbar-button" onClick={() => handleAction('Italic')}>
//                                                 <FaItalic />
//                                             </button>
//                                             <button className="toolbar-button" onClick={() => handleAction('Emoji')}>
//                                                 <FaSmile />
//                                             </button>
//                                             <button className="toolbar-button" onClick={handleAddVariable}>
//                                                 <FaPlusCircle /> Add Variable
//                                             </button>
//                                             <button className="toolbar-button" onClick={() => handleAction('Info')}>
//                                                 <FaInfoCircle />
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/* Render input fields for variables */}
//                                 <div className="variables-container">
//                                     {variableFields.map((variable) => (
//                                         <div key={variable.index} className="variable-input">
//                                             <span>{`{{${variable.index}}}`}</span>
//                                             <input
//                                                 type="text"
//                                                 placeholder={`Enter value for {{${variable.index}}}`}
//                                                 value={variable.value}
//                                                 onChange={(e) => handleVariableChange(variable.index, e.target.value)}
//                                             />
//                                             <button
//                                                 className="delete-variable-button"
//                                                 onClick={() => handleDeleteVariable(variable.index)}
//                                             >
//                                                 <FaMinusCircle />
//                                             </button>
//                                         </div>
//                                     ))}
//                                 </div>
//                                 <p className='txt1'>Footer<span className="optional"> • Optional</span></p>
//                                 <div className="footer-section">
//                                     <input
//                                         type="text"
//                                         placeholder="Enter footer text..."
//                                         className="footer-input"
//                                         value={footerText}
//                                         maxLength={100}
//                                         onChange={(e) => setFooterText(e.target.value)}
//                                     />
//                                     <div className="char-count">Characters: {footerText.length} / 100</div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="btttns">
//                             <div className="dropdown-container">
//                                 <h5 className='text11'>Buttons<span className="optional"> • Optional</span></h5>
//                                 <p>Create buttons that let customers respond to your message or take action. You can add up to 10 buttons. If you add more than 3 buttons, they will appear in a list.</p>
//                                 <select
//                                     id="main-dropdown"
//                                     className="dropdown"
//                                     value={mainSelection}
//                                     onChange={handleSelectChange}
//                                 >
//                                     <option value="">   + Add button</option>
//                                     <option value="option1">Quick Reply</option>
//                                     <option value="option2">None</option>
//                                 </select>

//                                 {showAdditionalFields && (
//                                     <div className="additional-fields">
//                                         <div className="field-container">
//                                             <label htmlFor="secondary-dropdown" className="dropdown-label">Secondary Option:</label>
//                                             <select
//                                                 id="secondary-dropdown"
//                                                 className="dropdown"
//                                                 value={secondaryOption}
//                                                 onChange={handleSecondaryOptionChange}
//                                             >
//                                                 <option value="">Select...</option>
//                                                 <option value="QUICK_REPLY">QUICK_REPLY</option>
//                                                 <option value="none">None</option>
//                                             </select>
//                                         </div>

//                                         <div className="field-container">
//                                             <label htmlFor="input-text" className="dropdown-label">Input Text:</label>
//                                             <input
//                                                 type="text"
//                                                 id="input-text"
//                                                 className="input-text"
//                                                 value={inputText}
//                                                 onChange={handleInputTextChange}
//                                             />
//                                         </div>

//                                         <button type="button" className='btn-primary' onClick={addButton}>Add Button</button>
//                                     </div>
//                                 )}

//                                 <div className="added-buttons">
//                                     {buttons.map((btn, index) => (
//                                         <div key={index} className="button-preview">
//                                             {/* <span>{btn.type}: {btn.text}</span> */}
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                     <div className="box right-box">
//                         <div className="previwetemp">
//                             {/* Preview Template Section */}
//                             <div className="prebox1">
//                                 <h3>Preview Template</h3>
//                                 <div className='immmg'>

//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//                 <div className="buttons-container12">
//                     <button className="start-button" onClick={onBackClick}>Previous</button>
//                     <button type="submit" className="review-button" onClick={handleSubmit}>Review yor template


//                     </button>

//                 </div>
//                 <ErrorModal
//                     showError={showError}
//                     errorMessage={errorMessage}
//                     onClose={handleCloseModal}
//                 />
//             </div>
//         </>
//     );
// };

// export default NextComponent;





















import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import './NextComponent.css';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBullhorn, FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle, FaImage, FaVideo, FaFileAlt, FaMapMarkerAlt, FaTextHeight, FaMinusCircle } from 'react-icons/fa';
import HeaderSection from './HeaderSection';
import BodySection from './BodySection';
import FooterSection from './FooterSection';
import ButtonSection from './ButtonSection';
import ErrorModal from '../ErrorModal/ErrorModal';
import ChatPreview from '../ChatPreview';

const NextComponent = ({ onBackClick, selectedCategory }) => {
    // Define all state variables and functions as in your original code
    const [selectedOption, setSelectedOption] = useState('None');
    const [text, setText] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [file, setFile] = useState(null);
    const [mainSelection, setMainSelection] = useState('');
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [language, setLanguage] = useState('en_US');
    const [buttons, setButtons] = useState([]);
    const [secondaryOption, setSecondaryOption] = useState('');
    const [inputText, setInputText] = useState('');
    const [message, setMessage] = useState(''); // Renamed from 'text' to 'message'
    const [variablesList, setVariablesList] = useState([]); // Renamed from 'variables'
    const [variableCount, setVariableCount] = useState(1); // Renamed from 'nextVariableIndex'
    const [bodyText, setBodyText] = useState('');
    const [variableFields, setVariableFields] = useState([]);
    const [nextVariableIndex, setNextVariableIndex] = useState(1);
    const [variableHeaderAdded, setVariableHeaderAdded] = useState(false);
    const [variableText, setVariableText] = useState(''); // Text for the variable
    const [variableHeaderValue, setVariableHeaderValue] = useState(''); // Value of the variable
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);

    const [selectedHeaderOption, setSelectedHeaderOption] = useState('');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [headerFile, setHeaderFile] = useState(null);
    const [headerVariableAdded, setHeaderVariableAdded] = useState(false);
    const [headerVariableValue, setHeaderVariableValue] = useState('');
    const options = [
        { value: 'None', label: 'None' },
        { value: 'Image', label: 'Image', icon: <FaImage /> },
        { value: 'Text', label: 'Text', icon: <FaTextHeight /> },
        { value: 'Video', label: 'Video', icon: <FaVideo /> },
        { value: 'Document', label: 'Document', icon: <FaFileAlt /> },
        { value: 'Location', label: 'Location', icon: <FaMapMarkerAlt /> },
    ];

    //Header functions


    const [headerVariableFields, setHeaderVariableFields] = useState([]);
 const [variableAdded, setVariableAdded] = useState(false);
    const [variableValue, setVariableValue] = useState('');


    // Add a new variable for the header


    // Handle variable value change for header


    const handleVariableAdd = () => setVariableAdded(true);
    const handleVariableRemove = () => {
        setVariableAdded(false);
        setVariableValue('');
    };

    // const handleHeaderVariableChange = (value) => setVariableValue(value);


    const handleHeaderVariableChange = (newValue) => {
        setVariableValue(newValue); // This updates the state correctly
    };
  
 
    // Function to add variable
   

    const handleHeaderTextChange = (e) => {
        setHeaderText(e.target.value);
    };

    // Function to add a header variable
    const handleHeaderVariableAdd = () => {
        setHeaderVariableFields([{ value: '' }]); // Initialize with an empty variable value
    };

    // Function to remove a header variable
    const handleHeaderVariableRemove = () => {
        setHeaderVariableFields([]); // Clear variable fields when removing
    };

    // Function to update the header variable value in the state
    

   




  
   

    //BODY functions
    const extractVariables = (text) => {
        const regex = /{{(\d+)}}/g;
        const matches = [...text.matchAll(regex)];
        const indexes = matches.map((match) => parseInt(match[1], 10));
        // Sort indexes in ascending order
        indexes.sort((a, b) => a - b);
        return indexes;
    };


    useEffect(() => {
        // Extract variables whenever bodyText changes
        const detectedVariables = extractVariables(bodyText);

        setVariableFields((prevVariables) => {
            const currentVariableIndexes = prevVariables.map((variable) => variable.index);
            const newVariables = detectedVariables.filter((index) => !currentVariableIndexes.includes(index));
            const updatedVariables = [
                ...prevVariables,
                ...newVariables.map((index) => ({ index, value: '' })),
            ];

            // Remove variables that are not in the bodyText anymore
            return updatedVariables.filter((variable) => detectedVariables.includes(variable.index));
        });

        // Update next variable index to the highest current + 1
        const maxIndex = detectedVariables.length > 0 ? Math.max(...detectedVariables) : 0;
        setNextVariableIndex(maxIndex + 1);
    }, [bodyText]);


    const handleAddVariable = () => {
        const newVariable = `{{${nextVariableIndex}}}`;
        setBodyText((prevBodyText) => prevBodyText + ' ' + newVariable);
        setNextVariableIndex((prevIndex) => prevIndex + 1);
    };


    const handleDeleteVariable = (index) => {
        setVariableFields((prevVariables) =>
            prevVariables.filter((variable) => variable.index !== index)
        );
        setBodyText((prevBodyText) => prevBodyText.replace(`{{${index}}}`, ''));
    };



    const handleVariableChange = (index, value) => {
        setVariableFields((prevVariables) =>
            prevVariables.map((variable) =>
                variable.index === index ? { ...variable, value } : variable
            )
        );
    };



    // const handleSelectChange = (event) => {
    //     const value = event.target.value;
    //     setMainSelection(value);
    //     // Update `showAdditionalFields` based on the selected value
    //     setShowAdditionalFields(value === 'option1'); // Show additional fields if 'Quick Reply' is selected
    // };


    const handleSelectChange = (event) => {
        const value = event.target.value;
        setMainSelection(value);
        setShowAdditionalFields(value === 'option1'); // Adjust according to the selected value
    };




    const handleOptionChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);

        if (value !== 'Text') {
            setHeaderText('');
        }

        if (['Image', 'Video', 'Document'].includes(value)) {
            document.getElementById('fileInput').click();
        }
    };

    const handleSecondaryOptionChange = (event) => {
        setSecondaryOption(event.target.value);
    };

    const handleInputTextChange = (event) => {
        setInputText(event.target.value);
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleAction = (action) => {
        console.log(`${action} button clicked`);
    };
    const addButton = () => {
        if (mainSelection === 'option1' && inputText.trim()) {
            const newButton = {
                type: secondaryOption || 'QUICK_REPLY',
                text: inputText,
            };
            setButtons([...buttons, newButton]);
            // Clear input fields after adding button
            setSecondaryOption('');
            setInputText('');
        }
    };

    const handleUpdateButtons = (updatedButtons) => {
        setButtons(updatedButtons);
        console.log('Updated buttons:', updatedButtons); // For debugging
    };

    const token = localStorage.getItem('token');
    console.log('Stored Token:', token); // Log the token to check its presence

    const generateJSON = (userId) => {
        // Generate example body text based on variable values as a single array of strings
        const exampleBodyText =
            variableFields.length > 0
                ? [variableFields.map((variable) => variable.value)] // Combine all variable values into one array
                : [];

        const exampleHeaderText = variableAdded ? [variableValue] : [];

        // Initialize the components array
        const components = [];

        // Add the HEADER component only if headerText exists
        if (headerText) {
            components.push({
                type: 'HEADER',
                format: 'TEXT',
                text: headerText,
                ...(exampleHeaderText.length > 0
                    ? {
                        example: {
                            header_text: exampleHeaderText, // Use the variable input value
                        },
                    }
                    : {}),
            });
        }

        // Add the BODY component
        components.push({
            type: 'BODY',
            text: bodyText || 'it is new one{{1}}',
            ...(exampleBodyText.length > 0
                ? {
                    example: {
                        body_text: exampleBodyText,
                    },
                }
                : {}),
        });

        // Add the FOOTER component if footerText exists
        if (footerText) {
            components.push({
                type: 'FOOTER',
                text: footerText || 'Footer text',
            });
        }

        // Add BUTTONS if any buttons are present
        if (buttons.length > 0) {
            components.push({
                type: 'BUTTONS',
                buttons: buttons.map(button => ({
                    type: button.type || "QUICK_REPLY",
                    text: button.text || "Default Button Text",
                })),
            });
        }

        const jsonData = {
            name: templateName,
            code: {
                name: templateName,
                language: language,
                category: selectedCategory || 'MARKETING',
                components: components, // Use the constructed components array
            },
            userid: userId,
        };

        console.log(JSON.stringify(jsonData, null, 2));
        return jsonData;
    };

   
    const refreshToken = async () => {
        try {
            const response = await axios.post('https://api.example.com/refresh-token', {
                // Provide necessary details to refresh the token
            });

            // Save the new token
            localStorage.setItem('token', response.data.token);
            return response.data.token;
        } catch (error) {
            console.error('Error refreshing token:', error.message);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        let token = localStorage.getItem('token');


        if (!bodyText.trim()) {
            setErrorMessage('Body text is required'); // Set error message for empty body text
            setShowError(true); // Show error modal
            setLoading(false); // Stop loading
            return; // Stop execution if body text is empty
        }

        if (token) {
            try {
                // Check token validity or refresh if needed
                const decodedToken = jwtDecode(token);
                const isTokenExpired = new Date().getTime() / 1000 > decodedToken.exp; // Token expiry check

                if (isTokenExpired) {
                    token = await refreshToken();
                }

                const jsonData = generateJSON(decodedToken.id);

                const response = await axios.post(
                    `https://api.lohith.in:3007/api/v1/wa/create_wa_template`,
                    jsonData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                console.log('API response:', response.data);
                alert("Template created sucussfully")
                navigate('/main/create_whatsapp_template');
            } catch (error) {
                console.error('Error occurred while sending data:', error.message);
                console.error('API error message:', error.response?.data?.message);
                console.error('API error details:', error.response?.data?.error);
                setErrorMessage(error.response?.data?.message); // Set the error message from the response
                setShowError(true); // Show the error modal
            }
        } else {
            console.error('Token not found.');
            // Handle missing token scenario
        } e.preventDefault();
        setLoading(true);
        setError(null);
    };

    const handleCloseModal = () => {
        setShowError(false);
        setErrorMessage('');
    };
    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="next-component-container">
                <h2 className='p2'>Create Template</h2>
                <div className="box-container">
                    <div className="box left-box">
                        <div className="firsthead">
                            <div className="icon-container">
                                <FaBullhorn className="icon1" />
                            </div>
                            <div className="text-container">
                                <h2 className='hhh2'>Your_template_name • English</h2>
                                <p>Marketing • Custom</p>
                            </div>
                        </div>
                        <div className="tempname">
                            <h5>Template name and language</h5>
                            <div className="template-input">
                                <p className='text11'>Name your Template</p>
                                <div className="input-group">
                                    <label className="input-label" htmlFor="templateName">Template Name</label>
                                    <label className="input-label" htmlFor="selectLanguage">Select Language</label>
                                    <input
                                        id="templateName"
                                        type="text"
                                        placeholder="Enter a template name"
                                        maxLength={512}
                                        className="template-input-field"
                                        value={templateName}
                                        onChange={(e) => setTemplateName(e.target.value)}
                                    />
                                    <select id="selectLanguage" className="language-select" value={language} onChange={(e) => setLanguage(e.target.value)}>
                                        <option value="en_US">en_US</option>
                                        <option value="Spanish">Spanish</option>
                                        <option value="French">French</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="hbf">
                            <h5><b>Content</b></h5>

                            <p>Fill out your header, body and footer section of your template</p>
                            

                            {/* <HeaderSection
                                selectedOption={selectedOption}
                                headerText={headerText}
                                onOptionChange={handleOptionChange}
                                onHeaderTextChange={handleHeaderTextChange}
                                onFileChange={handleFileChange}
                                file={file}
                                onVariableAdd={handleVariableAdd}
                                onVariableRemove={handleVariableRemove}
                                onVariableChange={handleVariableChange}
                                variableAdded={variableAdded}
                                variableValue={variableValue}
                            /> */}

                            <HeaderSection
                                selectedOption={selectedOption}
                                headerText={headerText}
                                onOptionChange={handleOptionChange}
                                onHeaderTextChange={handleHeaderTextChange}
                                onFileChange={handleFileChange}
                                file={file}
                                onVariableAdd={handleVariableAdd}
                                onVariableRemove={handleVariableRemove}
                                onVariableChange={handleHeaderVariableChange} // Updated function name here
                                variableAdded={variableAdded}
                                variableValue={variableValue}
                            />
                            <BodySection
                                bodyText={bodyText}
                                onBodyTextChange={(e) => setBodyText(e.target.value)}
                                handleAddVariable={handleAddVariable}
                                variableFields={variableFields}
                                handleVariableChange={handleVariableChange}
                                handleDeleteVariable={handleDeleteVariable}
                            />

                            <FooterSection
                                footerText={footerText}
                                onFooterTextChange={(e) => setFooterText(e.target.value)}
                            />
                        </div>

                        <ButtonSection
                            mainSelection={mainSelection}
                            showAdditionalFields={showAdditionalFields}
                            secondaryOption={secondaryOption}
                            inputText={inputText}
                            buttons={buttons}
                            handleSelectChange={(e) => setMainSelection(e.target.value)}
                            handleSecondaryOptionChange={(e) => setSecondaryOption(e.target.value)}
                            handleInputTextChange={(e) => setInputText(e.target.value)}
                            addButton={addButton}
                            onUpdateButtons={handleUpdateButtons}
                        />

                    </div>
                    <div className="box right-box">
                        <div className="previwetemp">
                            {/* Preview Template Section */}


                            <div className="prebox1">
                                <h3>Preview Template</h3>
                                <ChatPreview
                                    headerText={headerText}
                                    bodyText={bodyText}
                                    footerText={footerText}
                                    buttons={buttons}
                                />
                            </div>


                        </div>
                    </div>
                </div>
                <div className="buttons-container12">
                    <button className="start-button" onClick={onBackClick}>Previous</button>
                    <button type="submit" className="review-button" onClick={handleSubmit}>Review yor template


                    </button>

                </div>
            </div>



            {/* Error Modal */}
            {ErrorModal && (
                <ErrorModal
                    showError={showError}
                    errorMessage={errorMessage}
                    onClose={handleCloseModal}
                />
            )}

        </>
    );
};

export default NextComponent;