// TemplateModal.js
import React, { useState } from 'react';
import axios from 'axios';
import ModalImg from '../assets/images/modal.jpg';
import './TemplateModal.css';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useTheme } from './ThemeContext';

const TemplateModal = ({ show, onClose, onTemplateCreated,onSubmit }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { theme } = useTheme(); // Get the current theme

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            const createdBy = decodedToken.id;

            const response = await axios.post(
                'https://api.lohith.in:3007/api/v1/templates/create-template',
                { name, description, created_by: createdBy },
                { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );

            alert('Template created successfully!12');
            setName('');
            setDescription('');
            onClose(); // Close modal
            onTemplateCreated(); // Notify parent component
            onSubmit();
            // navigate('/create_whatsapp_template');
        } catch (error) {
            setError('Failed to create template. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (!show) return null;

    return (
        <div className={`model-backdro1 ${theme}`}>
            <div className={`modal-cont1 ${theme}`}>
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-content-wrapper">
                    <div className="modal-image">
                        <img src={ModalImg} alt="Modal" />
                    </div>
                    <div className="modal-form-content">
                        <h2>Create Template</h2>
                        <p className="des">Create Your own template here!!!!</p>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="templateName" className="lab1">Template Name:</label>
                                <input
                                    type="text"
                                    id="templateName"
                                    name="templateName"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="templateDescription" className="lab1">Description:</label>
                                <textarea
                                    id="templateDescription"
                                    name="templateDescription"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </div>
                            {error && <p className="error">{error}</p>}
                            <button type="submit" disabled={loading} className="submodal">
                                {loading ? 'Creating...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateModal;
