import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaImage, FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle } from 'react-icons/fa';
import './HeaderSection.css';

const HeaderSection = ({
    selectedOption,
    headerText,
    onOptionChange,
    onHeaderTextChange,
    onFileChange,
    file,
    onVariableAdd,
    onVariableRemove,
    onVariableChange: handleHeaderVariableChange, // Updated function name
    variableAdded,
    variableValue,
}) => {
    const [showMessage, setShowMessage] = useState(false);

    const addVariable = () => {
        if (!variableAdded) {
            onHeaderTextChange({ target: { value: `${headerText} {{1}}` } });
            onVariableAdd(); // Notify parent component about the variable addition
            setShowMessage(false);
        } else {
            setShowMessage(true);
        }
    };

    const removeVariable = () => {
        onHeaderTextChange({ target: { value: headerText.replace(' {{1}}', '') } });
        onVariableRemove(); // Notify parent component about the variable removal
        setShowMessage(false);
    };

    return (
        <div className="header-container">
            <p className="text11">
                Header <span className="optional">• Optional</span>
            </p>
            <div className="header-section">
                <select
                    value={selectedOption}
                    onChange={onOptionChange}
                    className="header-dropdown"
                >
                    <option value="None">None</option>
                    <option value="Image">Image</option>
                    <option value="Text">Text</option>
                </select>

                {selectedOption === 'Text' && (
                    <>
                        <input
                            type="text"
                            placeholder="Enter header text..."
                            className="header-input"
                            value={headerText}
                            maxLength={50}
                            onChange={onHeaderTextChange}
                        />
                        <div className="toolbar-button-group">
                            <button className="toolbar-button">
                                <FaBold />
                            </button>
                            <button className="toolbar-button">
                                <FaInfoCircle />
                            </button>
                            <button className="toolbar-button">
                                <FaItalic />
                            </button>
                            <button className="toolbar-button">
                                <FaSmile />
                            </button>
                            <button
                                className="toolbar-button"
                                onClick={addVariable}
                                disabled={variableAdded}
                            >
                                <FaPlusCircle /> Add Variable
                            </button>
                        </div>

                        {variableAdded && (
                            <div className="variable-input-group">
                                <span className="variable-label">{`{{1}}`}</span>
                                <input
                                    type="text"
                                    className="variable-input"
                                    placeholder="Enter value"
                                    value={variableValue}
                                    onChange={(e) => handleHeaderVariableChange(e.target.value)} // Updated function usage
                                />
                                <button
                                    className="remove-variable-button"
                                    onClick={removeVariable}
                                >
                                    Remove
                                </button>
                            </div>
                        )}

                        {showMessage && (
                            <p className="warning-message">You can add only once.</p>
                        )}
                    </>
                )}

                {selectedOption === 'Image' && (
                    <button
                        className="upload-button"
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        <FaImage /> Upload Image
                    </button>
                )}

                <input
                    id="fileInput"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                />

                {file && <p className="file-name">Selected File: {file.name}</p>}
            </div>
        </div>
    );
};

HeaderSection.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    onOptionChange: PropTypes.func.isRequired,
    onHeaderTextChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    file: PropTypes.object,
    onVariableAdd: PropTypes.func.isRequired,
    onVariableRemove: PropTypes.func.isRequired,
    onVariableChange: PropTypes.func.isRequired, // Updated prop type for handleHeaderVariableChange
    variableAdded: PropTypes.bool.isRequired,
    variableValue: PropTypes.string.isRequired, // Define prop type for variable value
};

export default HeaderSection;
