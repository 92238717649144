import React from 'react';
import './FooterSection.css';
const FooterSection = ({ footerText, onFooterTextChange }) => {
    return (
        <div>
        
            <p className='txt1'>Footer<span className="optional"> • Optional</span></p>
            <div className="footer-section">
                <input
                    type="text"
                    placeholder="Enter footer text..."
                    className="footer-input"
                    value={footerText}
                    maxLength={100}
                    onChange={onFooterTextChange}
                />
                <div className="char-count">Characters: {footerText.length} / 100</div>
            </div>
        </div>
    );
};

export default FooterSection;
