// ChatboxPrev.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './chatboxPrev.css'; // Add your CSS styles here

const ChatboxPrev = ({ header, body, footer, button }) => {
    return (
        <>
        <div className="chatbox-prev">
            {header && <div className="chatbox-header">{header}</div>}
            {body && <div className="chatbox-body">{body}</div>}
            {footer && <div className="chatbox-footer">{footer}</div>}
                
        </div>
            {button && <button className="chatbox-button">{button}</button>}
        </>
    );
};

ChatboxPrev.propTypes = {
    header: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
    button: PropTypes.string,
};

export default ChatboxPrev;
