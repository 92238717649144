// src/components/Content2.js
import React from 'react';
import backgroundImage from '../assets/bgd.jpg';

const contentStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    minHeight: '100vh', // Adjust as needed
    padding: '20px',    // Adjust as needed
    color: 'white',     // Adjust text color for better readability
    maxWidth: '100%',
    color: 'black'
};
const Content2 = () => {
    return <div style={contentStyle}><h2>Content 2</h2><p>This is the content for option 2.</p>
 
    </div>;
};

export default Content2;
