import React from 'react';
import './Chatbox.css'; // We'll add some styling for this too

const Chatbox = ({ headerText, headerVariables, bodyText, bodyVariables, footerText, buttonText }) => {
    // Replace {{n}} placeholders with corresponding variables in both header and body
    const replacePlaceholders = (text, variables) => {
        let replacedText = text;
        variables.forEach((variable, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, variable || `{{${index + 1}}}`);
        });
        return replacedText;
    };

    return (
        <>
        <div className="chatbox">
            <div className="chat-header">{replacePlaceholders(headerText, headerVariables)}</div>
            <div className="chat-body">{replacePlaceholders(bodyText, bodyVariables)}</div>
            <div className="chat-footer">{footerText}</div>
           
        </div>
         <div className="chat-buttons12">
                {buttonText && (
                    <button className="chat-button1">{buttonText}</button>
                )}
            </div>
            </>
    );
};

export default Chatbox;
