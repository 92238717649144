import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Register/Register.css'; // Import the CSS file

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');

        const payload = { email, password, name };

        try {
            const response = await axios.post('https://api.lohith.in:3007/api/v1/auth/register', payload);
            console.log('Register Success:', response.data);

            // Redirect to the login page after successful registration
            navigate('/login');
        } catch (error) {
            if (error.response) {
                console.error('Response Error:', error.response.data);
                setError(error.response.data.message || 'Registration failed');
            } else if (error.request) {
                console.error('Request Error:', error.request);
                setError('No response from the server');
            } else {
                console.error('Error:', error.message);
                setError(error.message);
            }
        }
    };

    return (
        <div className="register-container">
            <div className="register-form-container">
                <h2 className="register-title">Register</h2>
                <p>Before continue, please Sign In first!</p>
                <form onSubmit={handleRegister} className="register-form">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            autoComplete="name"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete="email"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete="new-password"
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="btn1 ">Register</button>
                </form>
                <p className="register-footer">Already registered? <button onClick={() => navigate('/login')} className="link-button">Login</button></p>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default Register;
