// src/App.js
import React from 'react';
import AppRouter from './AppRouter';
import { AuthProvider } from './AuthContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from './components/ThemeContext';


const App = () => (
  <ThemeProvider>
  <AuthProvider>
    <AppRouter />
  </AuthProvider>
  </ThemeProvider>
);

export default App;
