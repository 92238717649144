import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css'; // Import the custom CSS file
import loginImage from '../../assets/images/webbi.png';
import logoImage from '../../assets/whatsapp-logo.svg';
import content1 from '../../components/Content1';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        const payload = { email, password };

        try {
            const response = await axios.post('https://api.lohith.in:3007/api/v1/auth/login', payload);
            console.log('Login Success:', response.data);

            // Store the token in local storage
            localStorage.setItem('token', response.data.token);

            // Print the stored token in console
            const token = localStorage.getItem('token');
            console.log('Stored Token:', token);

            const decoded = jwtDecode(token);
            const userId = decoded.userId; // Adjust according to your token's payload structure
            console.log('User ID:', userId);
            // Redirect to the main layout
            navigate('/main');
        } catch (error) {
            if (error.response) {
                console.error('Response Error:', error.response.data);
                setError(error.response.data.message || 'Login failed');
            } else if (error.request) {
                console.error('Request Error:', error.request);
                setError('No response from the server');
            } else {
                console.error('Error:', error.message);
                setError(error.message);
            }
        }
    };

    return (
      
        
        <div className='login1'>
            <div className="logo-container">
                <img src={logoImage} alt="Logo" className="logo-image" />
                <h1 className="site-name">iissms-whatsapp</h1>
            </div>
            <div className="login-container">
               
                <div className="login-right">
                    <h2 className="login-title">Let's you sign in</h2>
                    <p className="login-subtitle">Welcome to our Page <a href="/register">Sign Up</a></p>
                    <form onSubmit={handleLogin} className="login-form">
                        <div className="form-grouplogin">
                            <label>Username:</label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete="email"
                            />
                        </div>
                        <div className="form-grouplogin">
                            <label>Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                autoComplete="current-password"
                            />
                        </div>
                        <div className="form-grouplogin form-check">
                            <input type="checkbox" className="form-check-input" id="keepLoggedIn" />
                            <label className="form-check-label" htmlFor="keepLoggedIn">Keep me logged in</label>
                        </div>
                        <button type="submit" className="btn1 btn-block">Sign In</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
                <div className="login-left">
                    <img src={loginImage} alt="Login Illustration" className="login-image" />
                
                </div>
            </div>
        </div>

    
    );
};

export default Login;
