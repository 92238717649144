import React, { useState, useEffect, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './TemplateList.css';
import Modal from './TemplateModal';
import { useTheme } from './ThemeContext';

import img1 from '../assets/images/cardsimg/img1.jpg';
import img2 from '../assets/images/cardsimg/img2.jpg';
import img3 from '../assets/images/cardsimg/img3.jpg';
import img4 from '../assets/images/cardsimg/img4.jpg';
import img5 from '../assets/images/cardsimg/img5.jpg';
import img6 from '../assets/images/cardsimg/img6.jpg';
import img7 from '../assets/images/cardsimg/img7.jpg';

const images = [img1, img2, img3, img4, img5, img6, img7];

const TemplateList = (props) => {
    const { theme } = useTheme();
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [visibleCards, setVisibleCards] = useState(16);
    const navigate = useNavigate();

    const fetchTemplates = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.id;
            try {
                const response = await axios.get(`https://api.lohith.in:3007/api/v1/templates/get-templates/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data && Array.isArray(response.data.templates)) {
                    const sortedTemplates = response.data.templates.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setTemplates(sortedTemplates);
                } else {
                    setTemplates([]);
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    navigate('/login');
                } else {
                    setError(error);
                    console.error('Error fetching templates:', error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    const handleCardClick = useCallback(async (template) => {
        console.log(`Card ID: ${template.id}`); // Print card ID in the console
        console.log(`Selected Template wa_template_id: ${template.wa_template_id}`); // Print wa_template_id of selected template

        if (template.status === 'created') {
            // Make an API call using the wa_template_id to check the status
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`https://api.lohith.in:3007/api/v1/wa/get_template_json/${template.wa_template_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                // Log the full response
                console.log('Full API Response:', JSON.stringify(response.data, null, 2));

                // Extract the status from the nested templateCode object
                const waTemplateStatus = response.data.templateCode.status; // Corrected to access the nested status

                // Log the status of the selected card in the console
                console.log(`Status of selected template with wa_template_id ${template.wa_template_id}: ${waTemplateStatus}`);

                // Check if the status is "APPROVED"
                if (waTemplateStatus === 'APPROVED') {
                    // Navigate to the new page /main/create_whatsapp_template with the template_id as a query parameter
                    navigate(`/main/create_whatsapp_template?template_id=${template.id}`);
                } else {
                    // Show an alert with the current status
                    alert(`Template status is: ${waTemplateStatus}`);
                }
            } catch (error) {
                console.error('Error fetching WhatsApp template:', error);
                alert('Failed to check WhatsApp template status. Please try again later.');
            }
        } else {
            // For other statuses, navigate to /main/create_whatsapp_template
            navigate(`/main/create_whatsapp_template?template_id=${template.id}`);
        }
    }, [navigate]);




    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleTemplateCreated = useCallback(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    const handleShowMore = useCallback(() => {
        setVisibleCards(prev => prev + 16);
    }, []);

    const handleCreateTemplate = () => {
        props.sethideTemplate(!props.hideTemplate);
    };

    return (
        <>
            <h1 style={{ fontSize: '1.5rem' }}>Recent Templates</h1>

            {error && <p className="error">ERROR: {error.message}</p>}
            <div className={`card-list ${theme}`}>
                <ul className={`card-list ${theme}`}>
                    <div className={`card_temp1 hover-card ${theme}`} onClick={() => setShowModal(true)}>
                        <h1 className="card-plus"><b>+</b></h1>
                        <h3 className="card-text">Create Template</h3>
                    </div>

                    {loading
                        ? Array.from({ length: visibleCards }).map((_, index) => (
                            <li key={index}>
                                <div className={`card is-loading ${theme}`}>
                                    <div className="image"></div>
                                    <div className="content">
                                        <h2></h2>
                                        <p></p>
                                    </div>
                                </div>
                            </li>
                        ))
                        : templates.slice(0, visibleCards).map((template, index) => (
                            <li key={index} onClick={() => handleCardClick(template)}>
                                <div className={`card_temp ${theme}`}>
                                    <img
                                        src={images[index % images.length]}
                                        className="card__image"
                                        alt="Template Image"
                                    />
                                    <div className={`card__overlay ${theme}`}>
                                        <div className="card__header">
                                            <div className="card__header-text">
                                                <h3 className="card__title">{template.name}</h3>
                                            </div>
                                        </div>
                                        <hr className='hl' />
                                        <p className="card__description">{template.description}</p>
                                    </div>
                                </div>
                            </li>
                        ))}

                    {visibleCards < templates.length && !loading && (
                        <div className="see-more-container">
                            <button className={`see-more-button ${theme}`} onClick={handleShowMore}>
                                <span>SEE MORE&gt;</span>
                            </button>
                        </div>
                    )}
                </ul>
            </div>

            <Modal onSubmit={handleCreateTemplate} show={showModal} onClose={handleCloseModal} onTemplateCreated={handleTemplateCreated} />
        </>
    );
};

export default memo(TemplateList);
