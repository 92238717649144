import React, { useState } from 'react';
import './SelectTemplate.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import { useTheme } from '../ThemeContext';
import NextComponent from '../nextComponent/NextComponent';
import marketingImage from '../../assets/images/modal.png';
import utilityImage from '../../assets/images/uisvg.png';
import authenticationImage from '../../assets/images/modal.png';
import { FaBullhorn, FaBell, FaLock } from 'react-icons/fa';

const SelectTemplate = () => {
    const { theme } = useTheme();
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [selectedOption, setSelectedOption] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedStep, setSelectedStep] = useState('setup');
    const [showNextComponent, setShowNextComponent] = useState(false);
    const [completedSteps, setCompletedSteps] = useState([]);

    const dropdownOptions = {
        Marketing: [
            { heading: 'Custom', description: 'Send promotions or announcements to increase awareness and engagement.' },
            { heading: 'Catalog', description: 'Send messages about your entire catalog or multiple products from it.' },
            { heading: 'Flows', description: 'Send a form to capture customer interests, appointment requests, or run surveys.' },
        ],
        Utility: [
            { heading: 'Reminder', description: 'Send reminders for appointments or other important dates.' },
            { heading: 'Notification', description: 'Inform users of updates or changes to their account or service.' },
            { heading: 'Survey', description: 'Collect feedback or conduct surveys.' },
        ],
        Authentication: [
            { heading: 'OTP', description: 'Send one-time passwords for secure transactions.' },
            { heading: 'Verification', description: 'Verify user identity or email/phone number.' },
            { heading: 'Security Alert', description: 'Alert users of potential security issues with their accounts.' },
        ],
    };

    const handleButtonClick = (category) => {
        setActiveDropdown((prev) => (prev === category ? category : category));
        setSelectedCategory(category);
    };

    const handleOptionChange = (category, option) => {
        setSelectedOption({ ...selectedOption, [category]: option });
    };

    const handleNextClick = () => {
        setShowNextComponent(true);
        setCompletedSteps([...completedSteps, selectedStep]);
        setSelectedStep('edit');
    };

    const handleBackClick = () => {
        setShowNextComponent(false);
        setSelectedStep('setup');
    };

    if (showNextComponent) {
        return <NextComponent onBackClick={handleBackClick} selectedCategory={selectedCategory} />;
    }

    const getButtonClass = (step) => {
        if (completedSteps.includes(step)) {
            return 'round-button completed';
        }
        return `round-button ${selectedStep === step ? 'active' : ''}`;
    };

    const getCategoryImage = () => {
        switch (selectedCategory) {
            case 'Marketing':
                return marketingImage;
            case 'Utility':
                return utilityImage;
            case 'Authentication':
                return authenticationImage;
            default:
                return null;
        }
    };

    return (
        <div className={`select-template-container ${theme}`}>
            <div>
                <Navbar />
                <Sidebar />
                <div className="bg">
                    <div className="flhead">
                        <h2>Create Template</h2>
                    </div>
                    <div className="flexbox-container">
                        <div className="flexbox-item">
                            <h6><b>Set up your templates</b></h6>
                            <p>
                                Choose the category that best describes your message template. Then select the type of message you want to
                                send. <a href="#">Learn more</a>
                            </p>
                            <span className="buttons">
                                <button
                                    className={activeDropdown === 'Marketing' ? 'active' : ''}
                                    onClick={() => handleButtonClick('Marketing')}
                                >
                                    <FaBullhorn className="icon" /> Marketing
                                </button>
                                <button
                                    className={activeDropdown === 'Utility' ? 'active' : ''}
                                    onClick={() => handleButtonClick('Utility')}
                                >
                                    <FaBell className="icon" /> Utility
                                </button>
                                <button
                                    className={activeDropdown === 'Authentication' ? 'active' : ''}
                                    onClick={() => handleButtonClick('Authentication')}
                                >
                                    <FaLock className="icon" /> Authentication
                                </button>
                            </span>

                            {activeDropdown && (
                                <div className="dropdown-box">
                                    <h4>{activeDropdown} Options</h4>
                                    <form>
                                        {dropdownOptions[activeDropdown].map((option, index) => (
                                            <label
                                                key={index}
                                                className={`radio-option ${selectedOption[activeDropdown] === option.heading ? 'selected' : ''}`}
                                            >
                                                <input
                                                    type="radio"
                                                    name={activeDropdown}
                                                    value={option.heading}
                                                    checked={selectedOption[activeDropdown] === option.heading}
                                                    onChange={() => handleOptionChange(activeDropdown, option.heading)}
                                                />
                                                <div className="option-content">
                                                    <b>{option.heading}</b>
                                                    <p>{option.description}</p>
                                                </div>
                                            </label>
                                        ))}
                                    </form>
                                </div>
                            )}
                        </div>

                        <div className="flexbox-item1">
                            <h6><b>Template Preview</b></h6>
                            {selectedCategory && (
                                <img
                                    src={getCategoryImage()}
                                    alt={`${selectedCategory} Illustration`}
                                    className="category-image"
                                />
                            )}
                        </div>
                    </div>

                    <div className="buttons-container">
                        <button className="start-button">Discard</button>
                        <button className="end-button" onClick={handleNextClick}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectTemplate;
