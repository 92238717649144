import React from "react";
import { useTheme } from '../ThemeContext'; // Import the useTheme hook
import './AdvBar.css';

const AdvBar = () => {
    const { theme } = useTheme(); // Use the theme from context

    return (
        <>
         
        <div className={`bar ${theme}`}>
       
            <h1 className="heading">WELCOME TO IISSMS - Create Your Templates</h1>
            <div className="search-container">
                <i className="fas fa-search search-icon"></i>
                <input
                    type="search"
                    id="search"
                    placeholder="Search your Template"
                    className={`search-input ${theme}`}
                />
            </div>
        </div>
        </>
    );
};

export default AdvBar;
