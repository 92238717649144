import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Chatbox from '../Chatbox/Chatbox'; // Import the Chatbox component
import './modifyTemplate.css';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import axios from 'axios';  // Add this line

const ModifyTemplate = () => {
    const location = useLocation();
    const { code } = location.state || {}; // Get the passed code data from CreateWhatsappTemplate
    const [headerText, setHeaderText] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [headerVariables, setHeaderVariables] = useState([]); // Store header variables
    const [bodyVariables, setBodyVariables] = useState([]); // Store body variables
    const [mobileNumber, setMobileNumber] = useState(''); // For Indian mobile number
    const [templateName, setTemplateName] = useState(''); // Template name state
    const [statuses, setStatuses] = useState([]); // New state to store the status of each request

    useEffect(() => {
        if (code) {
            const components = JSON.parse(code)?.components || [];
            const name = JSON.parse(code)?.name || ''; // Get template name from code

            // Set the template name in the state
            setTemplateName(name);

            components.forEach((component) => {
                switch (component.type) {
                    case 'HEADER':
                        setHeaderText(component.text || '');
                        setHeaderVariables(component.example?.header_text || []); // Set variables for header
                        break;
                    case 'BODY':
                        setBodyText(component.text || '');
                        setBodyVariables(component.example?.body_text?.flat() || []); // Set variables for body
                        break;
                    case 'FOOTER':
                        setFooterText(component.text || '');
                        break;
                    case 'BUTTONS':
                        setButtonText(component.buttons.map((b) => b.text).join(', '));
                        break;
                    default:
                        break;
                }
            });
        }
    }, [code]);

    const handleHeaderVariableChange = (index, value) => {
        const newVariables = [...headerVariables];
        newVariables[index] = value;
        setHeaderVariables(newVariables);
    };

    const handleBodyVariableChange = (index, value) => {
        const newVariables = [...bodyVariables];
        newVariables[index] = value;
        setBodyVariables(newVariables);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Split the input by commas to get multiple mobile numbers
        const mobileNumbers = mobileNumber.split(',').map(num => num.trim());

        // Validate each mobile number
        for (let num of mobileNumbers) {
            if (!/^[6-9]\d{9}$/.test(num)) {
                alert(`Please enter a valid 10-digit Indian mobile number: ${num}`);
                return;
            }
        }

        // Reset statuses before sending new requests
        setStatuses([]);

        // Loop through each mobile number and send the payload
        for (let num of mobileNumbers) {
            // Extract variables for the payload
            const headerParameters = headerVariables.map((variable) => ({
                type: "text",
                text: variable
            }));

            const bodyParameters = bodyVariables.map((variable) => ({
                type: "text",
                text: variable
            }));

            // Build the JSON payload
            const payload = {
                to: num,  // Send to each individual number
                templateName: templateName,
                templateData: {
                    name: templateName,
                    language: {
                        code: "en_US"
                    },
                    components: [
                        {
                            type: "HEADER",
                            parameters: headerParameters
                        },
                        {
                            type: "BODY",
                            parameters: bodyParameters
                        }
                    ]
                }
            };

            // Print payload to console for debugging
            console.log('Payload:', JSON.stringify(payload, null, 2));

            try {
                // Send the payload to the API
                const response = await axios.post('https://api.lohith.in:3007/api/v1/webhook/send-message', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`, // Add authorization if needed
                    }
                });

                // Update statuses with success for the number
                setStatuses(prevStatuses => [...prevStatuses, { num, status: 'Success', response: response.data }]);
                console.log(`API Response for ${num}:`, response);

            } catch (error) {
                console.error(`Error sending template to ${num}:`, error);
                let errorMessage = 'Unknown Error';

                if (error.response) {
                    errorMessage = error.response.data.message || errorMessage;
                } else if (error.message) {
                    errorMessage = error.message;
                }

                // Update statuses with failure for the number
                setStatuses(prevStatuses => [...prevStatuses, { num, status: 'Failed', error: errorMessage }]);
            }
        }
    };

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="modwhtemp">
            <form onSubmit={handleSubmit} className="modify-template-container">
                {/* Left side: Input fields */}
                <div className="template-inputs">
                    <h3>Modify WhatsApp Template</h3>

                    {/* Header Section */}
                    <div className="section">
                        <h4>Header</h4>
                        <input
                            type="text"
                            value={headerText}
                            onChange={(e) => setHeaderText(e.target.value)}
                            placeholder="Header Text"
                        />
                        {headerVariables.length > 0 && (
                            <div className="variables-section">
                                <h5>Header Variables</h5>
                                {headerVariables.map((variable, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={variable}
                                        onChange={(e) => handleHeaderVariableChange(index, e.target.value)}
                                        placeholder={`Header Variable {{${index + 1}}}`}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Body Section */}
                    <div className="section">
                        <h4>Body</h4>
                        <input
                            type="text"
                            value={bodyText}
                            onChange={(e) => setBodyText(e.target.value)}
                            placeholder="Body Text"
                        />
                        {bodyVariables.length > 0 && (
                            <div className="variables-section">
                                <h5>Body Variables</h5>
                                {bodyVariables.map((variable, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={variable}
                                        onChange={(e) => handleBodyVariableChange(index, e.target.value)}
                                        placeholder={`Body Variable {{${index + 1}}}`}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Footer Section */}
                    <div className="section">
                        <h4>Footer</h4>
                        <input
                            type="text"
                            value={footerText}
                            onChange={(e) => setFooterText(e.target.value)}
                            placeholder="Footer Text"
                        />
                    </div>

                    {/* Button Section */}
                    <div className="section">
                        <h4>Buttons</h4>
                        <input
                            type="text"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                            placeholder="Button Text"
                        />
                    </div>

                    {/* Indian Mobile Number Section */}
                    <div className="section">
                        <h4>Indian Mobile Numbers (comma separated)</h4>
                        <input
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder="Enter Indian Mobile Numbers (comma separated)"
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <button type="submit" className="submit-button">
                        Submit Template
                    </button>
                </div>

                {/* Right side: Live Chat Preview */}
                <div className="chat-preview">
                    <h4>Live Chat Preview</h4>
                    <Chatbox
                        headerText={headerText}
                        headerVariables={headerVariables}
                        bodyText={bodyText}
                        bodyVariables={bodyVariables}
                        footerText={footerText}
                        buttonText={buttonText}
                    />
                </div>

                {/* Statuses Section */}
                
            </form>
            {statuses.length > 0 && (
                <div className="status-section">
                    <h4>Send Status</h4>
                    {statuses.map((statusObj, index) => (
                        <div key={index} className={`status ${statusObj.status.toLowerCase()}`}>
                            <p>Mobile Number: {statusObj.num}</p>
                            <p>Status: {statusObj.status}</p>
                            {statusObj.status === 'Failed' && <p>Error: {statusObj.error}</p>}
                        </div>
                    ))}
                </div>
            )}
            </div>
        </>
    );
};

export default ModifyTemplate;
