import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faUsers,
    faClipboardList,
    faComments,
    faCalendarAlt,
    faChartBar,
    faSignal,
    faBan,
    faAnchor,
    faKey,
    faCog,
    faChevronRight,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../ThemeContext';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = React.memo(() => {
    const { theme } = useTheme();
    const [menuOpen, setMenuOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState('/main/content1');
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const [openMenu, setOpenMenu] = useState(null);

    const menuRef = useRef(null);
    const sidebarRef = useRef(null);

    const toggleSidebar = useCallback(() => setSidebarOpen(prevState => !prevState), []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current && !sidebarRef.current.contains(event.target) &&
                !menuRef.current?.contains(event.target)
            ) {
                setMenuOpen(false);
                setSidebarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleMenuItemClick = useCallback((path) => {
        setActiveMenu(path);
        setMenuOpen(false);
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    }, []);

    const handleSubmenuItemClick = useCallback((path) => {
        setActiveSubmenu(path);
        setMenuOpen(false);
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    }, []);

    const toggleSubmenu = useCallback((menu) => {
        setOpenMenu((prevMenu) => (prevMenu === menu ? null : menu));
    }, []);

    const renderSubmenu = useCallback((menu, icon, items) => (
        <li className={`sidebar-nav-item ${openMenu === menu ? 'open' : ''}`}>
            <span href="" className="sidebar-nav-link" onClick={() => toggleSubmenu(menu)}> 
                <div className="sidebar-menu-item">
                    <FontAwesomeIcon icon={icon} /> {menu}
                    <FontAwesomeIcon icon={openMenu === menu ? faChevronDown : faChevronRight} className="chevron-icon" />
                </div>
           </span>
            {openMenu === menu && (
                <ul className="sidebar-submenu">
                    {items.map((item, index) => (
                        <li key={index} className={`sidebar-submenu-item ${activeSubmenu === item.path ? 'active' : ''}`}>
                            <Link to={item.path} className="sidebar-submenu-link" onClick={() => handleSubmenuItemClick(item.path)}>
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    ), [openMenu, activeSubmenu, handleSubmenuItemClick, toggleSubmenu]);

    return (
        <>
            <button className={`hamburger-button ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                ☰
            </button>
            <div
                className={`sidebar ${theme} ${sidebarOpen ? 'open' : ''}`}
                style={{ zIndex: '999' }}
                ref={sidebarRef}
            >
                <ul className="sidebar-nav">
                    <h3 className='sideh3'>DASHBOARD</h3>
                    <hr />
                    <li className={`sidebar-nav-item ${activeMenu === '/main/content1' ? 'active' : ''}`}>
                        <Link to="/main/content1" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/content1')}>
                            <FontAwesomeIcon icon={faHome} /> Home
                        </Link>
                    </li>
                    <li className={`sidebar-nav-item ${activeMenu === '/main/create_whatsapp_template' ? 'active' : ''}`}>
                        <Link to="/main/create_whatsapp_template" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/create_whatsapp_template')}>
                            <FontAwesomeIcon icon={faComments} /> Create Whatsapp Template
                        </Link>
                    </li>

                    {renderSubmenu('Templates', faClipboardList, [
                        { label: 'Display', path: '/main/manager1' },
                        { label: 'Create', path: '/main/manager2' },
                        { label: 'Edit', path: '/main/manager3' },
                        { label: 'Delete', path: '/main/manager4' },
                    ])}

                    {renderSubmenu('Campaign', faUsers, [
                        { label: 'Create', path: '/main/content2' },
                    ])}

                    <li className={`sidebar-nav-item ${activeMenu === '/main/content4' ? 'active' : ''}`}>
                        <Link to="/main/content4" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/content4')}>
                            <FontAwesomeIcon icon={faComments} /> Live Chat
                        </Link>
                    </li>

                    {renderSubmenu('Schedule', faCalendarAlt, [
                        { label: 'Display', path: '/main/manager1' },
                        { label: 'Create', path: '/main/manager2' },
                        { label: 'Delete', path: '/main/manager4' },
                    ])}

                    {renderSubmenu('Report', faChartBar, [
                        { label: 'Summary', path: '/main/manager1' },
                        { label: 'Detailed Download', path: '/main/manager2' },
                        { label: 'Number Search', path: '/main/manager3' },
                    ])}

                    {renderSubmenu('MIS', faSignal, [
                        { label: 'WaBa Signal', path: '/main/manager1' },
                        { label: 'Logs', path: '/main/manager1' },
                        { label: 'Recharge', path: '/main/manager2' },
                        { label: 'DayCount', path: '/main/manager3' },
                    ])}

                    {renderSubmenu('Black List', faBan, [
                        { label: 'Display', path: '/main/manager1' },
                        { label: 'Add', path: '/main/manager2' },
                        { label: 'Remove', path: '/main/manager3' },
                    ])}

                    {renderSubmenu('Boat', faAnchor, [
                        { label: 'Create', path: '/main/manager2' },
                        { label: 'Display', path: '/main/manager1' },
                        { label: 'Edit', path: '/main/manager2' },
                        { label: 'Remove', path: '/main/manager3' },
                    ])}

                    {renderSubmenu('API', faKey, [
                        { label: 'View', path: '/main/manager1' },
                        { label: 'Request', path: '/main/manager1' },
                        { label: 'Remove', path: '/main/manager2' },
                    ])}

                    <li className={`sidebar-nav-item ${activeMenu === '/main/content8' ? 'active' : ''}`}>
                        <Link to="/main/content8" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/content8')}>
                            <FontAwesomeIcon icon={faCog} /> Settings
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
});

export default Sidebar;
