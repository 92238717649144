// import React from 'react';
// import '../ErrorModal/ErrorModal.css';

// const ErrorModal = ({ showError, errorMessage, onClose }) => {
//     if (!showError) return null;

//     return (
//         <div className="modal-overlay2">
//             <div className="modal-container2">
//                 <div className="modal-header2">
//                     <h2>Error</h2>
//                     <button className="close-button2" onClick={onClose}>×</button>
//                 </div>
//                 <div className="modal-body2">
//                     <p>{errorMessage}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ErrorModal;
import React from 'react';
import './ErrorModal.css'; // Import the CSS file

const ErrorModal = ({ showError, errorMessage, onClose }) => {
    if (!showError) return null;

    return (
        <div className="error-modal-overlay2">
            <div className="error-modal-container2">
                <button className="error-modal-close2" onClick={onClose}>
                    ×
                </button>
                <div className="error-modal-icon2">
                    <span>?</span>
                </div>
                <h2 className="error-modal-title2">ERROR</h2>
                <p className="error-modal-message2">{errorMessage}</p>
                <button className="error-modal-continue2" onClick={onClose}>
                    Continue
                </button>
            </div>
        </div>
    );
};

export default ErrorModal;
