import React, { useState } from 'react';
import './ButtonSection.css';

const ButtonSection = ({ onUpdateButtons }) => {
    const [buttons, setButtons] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [secondaryOption, setSecondaryOption] = useState('');
    const [inputText, setInputText] = useState('');
    const [isButtonAdded, setIsButtonAdded] = useState(false);

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSecondaryOptionChange = (e) => {
        setSecondaryOption(e.target.value);
    };

    const handleInputTextChange = (e) => {
        setInputText(e.target.value);
    };

    // Function to map the user-friendly button type to the required JSON value
    const mapButtonType = (type) => {
        switch (type) {
            case 'Quick Reply':
                return 'QUICK_REPLY';
            // Add more mappings here if needed in the future
            default:
                return type;
        }
    };

    const addButton = () => {
        if (selectedOption && secondaryOption && inputText) {
            // Use the mapButtonType function to convert the button type
            const mappedType = mapButtonType(selectedOption);
            const newButton = { type: mappedType, secondary: secondaryOption, text: inputText };
            const updatedButtons = [...buttons, newButton];
            setButtons(updatedButtons);
            setIsButtonAdded(true);

            // Update the parent with the new buttons
            onUpdateButtons(updatedButtons);
        }
    };

    const removeButton = (index) => {
        const updatedButtons = buttons.filter((_, i) => i !== index);
        setButtons(updatedButtons);
        setIsButtonAdded(false);

        // Update the parent with the new buttons after removal
        onUpdateButtons(updatedButtons);
    };

    return (
        <div className="hbf">
            <h5 className='text11'>Buttons<span className="optional"> • Optional</span></h5>
            <p>Create buttons that let customers respond to your message or take action. You can add up to 10 buttons. If you add more than 3 buttons, they will appear in a list.</p>
            <div className="button-section">
                <div className="dropdown-container">
                    <label htmlFor="main-dropdown" className="dropdown-label">Select an Option:</label>
                    <select
                        id="main-dropdown"
                        className="dropdown"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        disabled={isButtonAdded}
                    >
                        <option value="">+ Add button</option>
                        <option value="Quick Reply">Quick Reply</option>
                        <option value="None">None</option>
                    </select>

                    {selectedOption === 'Quick Reply' && (
                        <div className="additional-fields">
                            <div className="field-container">
                                <label htmlFor="secondary-dropdown" className="dropdown-label"></label>
                                <select
                                    id="secondary-dropdown"
                                    className="dropdown"
                                    value={secondaryOption}
                                    onChange={handleSecondaryOptionChange}
                                >
                                    <option value="">Select...</option>
                                    <option value="QUICK_REPLY">QUICK_REPLY</option>
                                    <option value="none">None</option>
                                </select>
                            </div>

                            <div className="field-container">
                                <label htmlFor="input-text" className="dropdown-label"></label>
                                <input
                                    type="text"
                                    id="input-text"
                                    className="input-text"
                                    value={inputText}
                                    onChange={handleInputTextChange}
                                />
                            </div>

                            <div className="button-actions">
                                <button className='btn-primary' onClick={addButton} disabled={isButtonAdded}>Add Button</button>
                                {isButtonAdded && (
                                    <button className='btn-remove' onClick={() => removeButton(buttons.length - 1)}>−</button>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="added-buttons">
                    {buttons.map((btn, index) => (
                        <div key={index} className="button-preview">
                            <span>{btn.type}: {btn.secondary}  {btn.text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ButtonSection;
